import * as React from "react";
import "../../gatsby-browser.js";
import Into from "../static/img/intro.png";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import HowAbout from "../component/Howwedo/HowAbout";
import HowTech from "../component/Howwedo/HowTech";
import { motion } from "framer-motion";
import axios from "axios";
import Load from "../static/img/load/load.gif";
import { url } from "../url/url";
import HowMethod from "../component/Howwedo/HowMethod";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import 'react-notifications/lib/notifications.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import ContactUs from "../static/img/ContactUs.svg";
import MetaTags from "../component/MetaTags.jsx";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,

      name: [],
      email: [],
      phone: "",
      message: [],
    };
  }

  getName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  getEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  getPhone = (value) => {
    this.setState({
      phone: value,
    });
  };

  getMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  postContact = () => {
    if (this.state.name == "") {
        NotificationManager.error("Name cannot be empty");
    } else if (this.state.email == "") {
        NotificationManager.error("Email cannot be empty");
    } else if (this.state.phone == "") {
        NotificationManager.error("Phone number cannot be empty");
    }
    //  else if (this.state.phone.length > 10 || this.state.phone.length < 10) {
    //     NotificationManager.warning(" Please check the number");
    // } 
    else if (this.state.message == "") {
        NotificationManager.error("Message cannot be empty");
    } else {
      axios({
        method: "POST",
        url: url + "/contact-us/",
        data: {
          username: this.state.name,
          email: this.state.email,
          phone_no: this.state.phone,
          message: this.state.message,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
                name: "",
                email: "",
                phone: "",
                message: "",
            });

            NotificationManager.success(" Message Has Sent ! ");
          }
        })
        .catch((err) => {
          NotificationManager.error(" Oops ! ");
        });
    }
  };

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { data, loading, name, email, phone, message } = this.state;

    return (
      <>
        <Navbar />

        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="howwedo" className="howwedo">
            <div align="center">
              <h1 className="light-text">
                Contact <span className="highlighted-text">Us</span>
              </h1>
              <img className="contact-banner" src={ContactUs} alt="" />
            </div>
          </section>

          <div className={"row"}>
            <div className={"col-sm-12 contact-form"}>
              <br />

              <div className="row">
                <div className={"col-md-6"}>
                  <label>Full Name</label>
                  <input
                    className={"form-control input-fields"}
                    placeholder={"Enter Name"}
                    value={name}
                    onChange={this.getName}
                  />{" "}
                  <br />
                </div>
                <div className={"col-md-6"}>
                  <label>Email</label>
                  <input
                    type={"email"}
                    value={email}
                    className={"form-control input-fields"}
                    placeholder={"Enter Email"}
                    onChange={this.getEmail}
                  />
                </div>
                <div className={"col-md-6"}>
                  <br />
                  <label>Mobile/Phone</label>
                  <PhoneInput
                  className={"form-control input-fields"}
      placeholder="Enter phone number"
      value={phone}
      onChange={this.getPhone}/>
                  {/* <input
                    type={"number"}
                    value={phone}
                    className={"form-control input-fields"}
                    placeholder={"Enter Phone"}
                    onChange={this.getPhone}
                  /> */}
                </div>
                <div className={"col-md-6"}>
                  <br />
                  <label> Country</label>
                  <input
                    type={"number"}
                    className={"form-control input-fields"}
                    placeholder={"Enter Your Country"}
                    onChange={this.getPhone}
                  />
                </div>
                <div className={"col-sm-12"}>
                  <br />
                  <label>Message</label>
                  <textarea
                    rows={"10"}
                    value={message}
                    className={"form-control input-fields"}
                    placeholder={""}
                    onChange={this.getMessage}
                  />
                </div>
              </div>

              <br />

              <div align="center">
                <button className={" btn btn-send "} onClick={this.postContact}>
                  {" "}
                  SEND MESSAGE{" "}
                </button>
              </div>
            </div>
          </div>

          <Footer />
        </motion.div>

        <NotificationContainer />
        <MetaTags title={"Contact Us | Koderbox"} />
      </>
    );
  }
}

export default Contact;
