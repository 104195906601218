import * as React from "react"
import '../../../gatsby-browser';

import Services from '../../static/img/img/ourwork.png'
import Load from "../../static/img/load/load.gif";
// import ReactHtmlParser  from 'react-html-parser';
import {url} from "../../url/url"

class HowMethod extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){

        const data = this.props.value[0];
        // console.log(url)

        return(
            <>
{/* 
                <section id="services" className="section-bg method">
                    <div className="container">

                        <header className="section-header">
                            <h3 className="process_head"> Methodology </h3>
                            <p>
                                <hr/>
                            </p>
                        </header>

                        <div className="row proces_part">

                            <div className="col-md-12  wow bounceInUp " data-wow-duration="1.4s">
                                <div className="box whatservice">
                                    {data == undefined ? (
                                        <div align="center" className="mt-5 mb-5">
                                            <img src={Load} />
                                        </div>
                                    ) : (
                                        <>
                                            <div className={"row"} align={"center"}>
                                                <img src={url+data.image[0].url} />
                                            </div>
                                            <p className="description">
                                                <br />
                                                { ReactHtmlParser(data.methodology) }
                                                <br /><br />
                                            </p>
                                        </>
                                    )}

                                </div>
                            </div>

                        </div>

                    </div>
                </section> */}

            </>
        )
    }
}

export default HowMethod